import { Link } from 'react-router-dom';
import '../Home/style.css'
import { useEffect } from 'react';

function Accueil() {
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <section className='main'>
          <article className="main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>AUBIN RG</h1>
                <p className='main-title-desc-fr'>réalisateur , producteur , vidéaste</p>
              </div>
            </div>
          </article>

          <section className='main-cards'>
            <article className='main-card'>
            <div className='main-card-image1'></div>
              <h2 className='main-card-titre'>services de tournage</h2>
              <p className='main-card-desc'>Je propose mes services de producteur vidéo et de réalisateur, avec une expertise dans divers domaines : films institutionnels, spots publicitaires, live streaming, interviews, clips musicaux
              </p>
              <div className='main-card-explore'>
              <button className='main-card-button'><Link className='main-lien-button' to="/work-fr">services</Link></button>
              </div>
            </article>
            <article className='main-card'>
              <div className='main-card-image2'></div>
              <h2 className='main-card-titre'>matériels</h2>
              <p className='main-card-desc'>Cameras Sony A7s, Trepieds, Slider motorisés, Stabilisateur, Micros Cravates HF , Micros Main HF, Drone ,Fond vert , Lumières. Matériel disponible à la location.
              </p>
              <div className='main-card-explore'>
              <button className='main-card-button'><Link className='main-lien-button' to="/gear-fr">équipement</Link></button>
              </div>
            </article>
          </section>
        </section>
      </main >
    </>
  );
}

export default Accueil;