import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../Header';
import Intro from '../Pages/Intro';
import Home from '../Pages/Home';
import Accueil from '../Pages/Accueil';
import Partenariat from '../Pages/Partenariat';
import Work from '../Pages/Work';
import About from '../Pages/About';
import APropos from '../Pages/APropos';
import Gear from '../Pages/Gear';
import Materiel from '../Pages/Materiel';
import Stock from '../Pages/Stock';
import Realisation from '../Pages/Realisation';
import Contact from '../Pages/Contact';
import Contactfr from '../Pages/ContactFr';
import Social from '../Social';
import Footer from '../Footer';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Intro />} />
                <Route
                    path="/*" // Change this to "*"
                    element={
                        <>
                            <Header />
                            <Routes>
                                <Route path="home" element={<Home />} />
                                <Route path="home-fr" element={<Accueil />} />
                                <Route path="work-fr" element={<Partenariat />} />
                                <Route path="work" element={<Work />} />
                                <Route path="about" element={<About />} />
                                <Route path="about-fr" element={<APropos />} />
                                <Route path="gear" element={<Gear />} />
                                <Route path="gear-fr" element={<Materiel />} />
                                <Route path="stock" element={<Stock />} />
                                <Route path="stock-fr" element={<Realisation />} />
                                <Route path="contact" element={<Contact />} />
                                <Route path="contact-fr" element={<Contactfr />} />
                            </Routes>
                            <Social />
                            <Footer />
                        </>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
