import '../Contact/style.css';
import { Outlet, Link } from "react-router-dom";
  import { useEffect } from 'react';
  
function Contactfr() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <section className='main'>
          <article className="contact-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>contact</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
          </article>

          <section className='contact-main-cards'>
            <article className='contact-main-content'>
              <div className='contact-main-card'>
                <p className='contact-main-card-desc'>Pour toutes questions sur une éventuelle collaboration, merci de m’envoyer un mail à l’adresse suivante :

                </p>
                <p className='contact-main-card-desc'>
                <a className='contact-main-mail' href="mailto:bookings@aubinrg.net"> bookings@aubin-rg.net</a>                  
                </p>
                <p className='contact-main-card-desc'>AUBIN RG</p>
                <p className='contact-main-card-desc'>PARIS, FRANCE</p>
              </div>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}

export default Contactfr;