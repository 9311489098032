import './style.css';
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';
import React, { useEffect } from 'react';
import image4 from '../../../../static/assets/img/photos/WORK/WORK.webp';
import image2 from '../../../../static/assets/img/photos/GEAR/GEAR\ 2.webp';
import image1 from '../../../../static/assets/img/photos/GEAR/Gear\ OK.webp';
import image3 from '../../../../static/assets/img/photos/GEAR/ts1gt-ypykz.webp';
// import image5 from '../../../../static/assets/img/photos/GEAR/WORK_ABOUT\ a\ def\ C.webp';

import $ from 'jquery'; // Make sure to include jQuery

// Define your Gear component
function Gear() {
  useEffect(() => {
    window.scrollTo(0, 0);
    initCarousel(); // Call the initialization function for the carousel
  }, []);

  // Function to handle moving to the selected item
  function moveToSelected(element) {
    if (element === 'next') {
      var selected = $('.selected').next();
    } else if (element === 'prev') {
      var selected = $('.selected').prev();
    } else {
      var selected = element;
    }

    var next = $(selected).next();
    var prev = $(selected).prev();
    var prevSecond = $(prev).prev();
    var nextSecond = $(next).next();

    $(selected).removeClass().addClass('selected');

    $(prev).removeClass().addClass('prev');
    $(next).removeClass().addClass('next');

    $(nextSecond).removeClass().addClass('nextRightSecond');
    $(prevSecond).removeClass().addClass('prevLeftSecond');

    $(nextSecond).nextAll().removeClass().addClass('hideRight');
    $(prevSecond).prevAll().removeClass().addClass('hideLeft');
  }

  // Initialize the carousel
  function initCarousel() {
    $('#carousel div').click(function () {
      moveToSelected($(this));
    });

    $('#prev').click(function () {
      moveToSelected('prev');
    });

    $('#next').click(function () {
      moveToSelected('next');
    });

    // Keyboard events
    $(document).keydown(function (e) {
      switch (e.which) {
        case 37: // left
          moveToSelected('prev');
          break;

        case 39: // right
          moveToSelected('next');
          break;

        default:
          return;
      }
      e.preventDefault();
    });
  }

  return (
    <>
      <main>
        <section className='main'>
          <article className="gear-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>gear</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <section className='gear-main-cards'>
            <article className='gear-main-content'>
              <div className='gear-main-card'>
                <p className='gear-main-card-desc'>Cameras Sony A7s2, Fx3, Fx6, Fs7, Canon EOS R5, Blackmagic Pocket, Ursa, Red Komodo 6k, Arri Alexa Mini, Tripods, Edelkrone motorized sliders, Stabilizer, Sennheiser HF lavalier microphones, Sennheiser HF handheld microphones, Dji drone, Screen green , Aputure lights.
                </p>
                <p className='gear-main-card-desc'>Several types of lighting, high-quality audio recording equipment for productions and other specialized equipment are available.
                </p>
              </div>
              {/* <article className='gear-main-photo'>
              <div className='gear-main-size-photo'>
              <div className='gear-main-card-image1'></div>
              </div>
              </article> */}
              
              <article className='gear-main-photo'>

              <div id="carousel">

                <div class="hideLeft">
                  <img src={image1} />
                </div>

                <div class="prevLeftSecond">
                  <img src={image2} />
                </div>

                <div class="prev">
                  <img src={image3} />
                </div>

                <div class="selected">
                  <img src={image4} />
                </div>

                {/* <div class="next">
                  <img src={image5} />
                </div> */}

                {/* <div class="nextRightSecond">
                  <img src={image6} />
                </div>

                <div class="hideRight">
                  <img src="https://i1.sndcdn.com/artworks-000064920701-xrez5z-t500x500.jpg" />
                </div> */}

              </div>

              </article>
              {/* <div class="buttons">
                <button id="prev">Prev</button>
                <button id="next">Next</button>
              </div> */}

              <article className='gear-main-button'>
                <div className='gear-main-card-explore'>
                  <button className='gear-main-card-button'><Link className='gear-lien-button' to="/work">services</Link></button>
                </div>
                <div className='gear-main-card-explore'>
                  <button className='gear-main-card-button'><Link className='gear-lien-button' to="/contact">hire aubin</Link></button>
                </div>
                <div className='gear-main-card-explore'>
                <button className='about-main-card-button'><Link className='main-lien-button' to="/about">about</Link></button>
                </div>
              </article>

            </article>
          </section>
        </section>
      </main>
    </>
  );
}

export default Gear;
