import '../About/style.css';
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';

function APropos() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  
  return (
    <>
      <main>
        <section className='main'>
          <article className="about-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>à propos</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
          </article>

          <section className='about-main-cards'>
            <article className='about-main-content'>
              <div className='about-main-card'>
                <div className='about-main-card-image1'></div>
                <p className='about-main-card-desc'>Passionné depuis toujours par la vidéo, je suis réalisateur et vidéaste depuis le début des années 2000.</p>
                
                
                <p className='about-main-card-desc about-space-line'>Durant toutes ces années, j'ai acquis une solide expérience</p>
                <p className='about-main-card-desc'>Je suis expert en réalisation et production audiovisuelle pour différents clients.</p>
                <p className='about-main-card-desc'>Je suis expert en réalisation et production audiovisuelle pour différents clients.</p>
                
                <p className='about-main-card-desc'>D'autre part, j'ai travaillé avec de nombreux médias, comme Europe 1, Virgin Radio, ainsi qu'avec d'innombrables sociétés de production et clients indépendants.
                </p>
                <p className='about-main-card-desc'>Je sais m'adapter à tous types de projets ambitieux ou plus succincts, afin de conseiller au mieux mes clients afin d'avoir le meilleur résultat possible pour leurs projets.
                </p>

                <div className='about-main-card-image2'></div>
                <p className='about-main-card-desc'>J'ai toujours été à la pointe des nouvelles technologies, mes capacités créatives et techniques sont illimitées et sans faille, je veille toujours à m'adapter à toutes les contraintes et à m'appliquer au maximum dans tous les projets qui me sont confiés.</p>
                <p className='about-main-card-desc'>Je voyage régulièrement en France, à travers l'Europe et dans le monde pour collaborer sur différents projets.
                </p>
                <p className='about-main-card-desc'>J'apprécie également de pouvoir rencontrer, échanger et partager mes connaissances avec des passionnés confirmés ou débutants.</p>
                {/* <p className='about-main-card-desc'>Ses capacités créatives sont illimitées et il est toujours à la pointe des nouvelles technologies.</p>
                <p className='about-main-card-desc'>Il voyage fréquemment à travers la France et l'Europe pour collaborer et partager ses techniques avec des cinéastes expérimentés et débutants.
                </p> */}

                <div className='about-main-story-image'>
                  <div className='about-main-card-image3'></div>
                  <div className='about-main-card-image4'></div>
                </div>
                <div className='about-main-story-image'>
                  <div className='about-main-card-image5'></div>
                  <div className='about-main-card-image6'></div>
                </div>
              <section className='about-main-button'>
                <div className='about-main-card-explore'>
                  <button className='about-main-card-button'><Link className='main-lien-button' to="/work-fr">services</Link></button>
                </div>
                <div className='about-main-card-explore'>
                  <button className='about-main-card-button'><Link className='main-lien-button' to="/contact-fr">travailler avec aubin</Link></button>
                </div>
                <div className='about-main-card-explore'>
                  <button className='about-main-card-button'><Link className='main-lien-button' to="/gear-fr">équipement</Link></button>
                </div>
              </section>
              </div>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}

export default APropos;
