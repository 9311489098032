import { Link } from 'react-router-dom';
import './style.css'
import facebook from '../../../static/assets/icon/facebook.png';
import twitter from '../../../static/assets/icon/twitter.png';
import insta from '../../../static/assets/icon/instagram.png';
import linkedin from '../../../static/assets/icon/linkedin.png';
import youtube from '../../../static/assets/icon/youtube.png';

function Social() {
  return (
    <>
        <section className='nav-social'>
          <div className='nav-social-box'>
            <Link className='nav-social-link' to="https://www.youtube.com/@BORDELIKprod" target="_blank"><img className='nav-social-icon' src={youtube} /></Link>
            <Link className='nav-social-link' to="https://instagram.com/aubin.rg_pro?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank"><img className='nav-social-icon' src={insta} /></Link>
            <Link className='nav-social-link' to="https://www.linkedin.com/in/aubin-raballand-givois-3b78b521?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"><img className='nav-social-icon' src={linkedin} /></Link>
            <Link className='nav-social-link' to="https://www.facebook.com/OboneFilms" target="_blank"><img className='nav-social-icon' src={facebook}/></Link>
          </div>
        </section>
    </>
  );
}

export default Social;