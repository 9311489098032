import './style.css'
import logo from '../../../static/assets/img/logo/logo.png';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function Footer() {
    const location = useLocation();
    const isFrUrl = location.pathname.endsWith('-fr');

    const linkText = {
        home: {
            '-fr': 'accueil',
            '': 'home',
        },
        about: {
            '-fr': 'à propos',
            '': 'about',
        },
        work: {
            '-fr': 'partenariat',
            '': 'work',
        },
        gear: {
            '-fr': 'équipement',
            '': 'gear',
        },
        stock: {
            '-fr': 'réalisation',
            '': 'stock',
        },
        contact: {
            '-fr': 'contact',
            '': 'contact',
        },
    };

    return (
        <>
            <footer class="footer">
                <section className='footer-contener'>
                    <article>
                        <nav className='footer-nav'>
                            <Link className='footer-lien' to={isFrUrl ? '/home-fr' : '/home'}>{linkText.home[isFrUrl ? '-fr' : '']}</Link>
                            <Link className='footer-lien' to={isFrUrl ? '/about-fr' : '/about'}>{linkText.about[isFrUrl ? '-fr' : '']}</Link>
                            <Link className='footer-lien' to={isFrUrl ? '/work-fr' : '/work'}>{linkText.work[isFrUrl ? '-fr' : '']}</Link>
                            <Link className='footer-lien' to={isFrUrl ? '/gear-fr' : '/gear'}>{linkText.gear[isFrUrl ? '-fr' : '']}</Link>
                            <Link className='footer-lien' to={isFrUrl ? '/stock-fr' : '/stock'}>{linkText.stock[isFrUrl ? '-fr' : '']}</Link>
                            <Link className='footer-lien' to={isFrUrl ? '/contact-fr' : '/contact'}>{linkText.contact[isFrUrl ? '-fr' : '']}</Link>
                        </nav>
                        <div className='footer-box'>
                        <div className='footer-text'>
                    {isFrUrl ? (
                        <p className='footer-item'>© COPYRIGHT AUBIN RG 2023. TOUS DROITS RÉSERVÉS</p>
                    ) : (
                        <p className='footer-item'>© COPYRIGHT AUBIN RG 2023. ALL RIGHTS RESERVED</p>
                    )}
                </div>
                            <img className='footer-logo' src={logo} alt="logo aubin" />
                        </div>
                    </article>
                <p className='linkWeb'>Propulsé par <a className="link" href="https://www.solveandoptimize.com/">Solve And Optimize</a> & <a className="link" href="https://www.web-maker-services.com/">Web Maker Services</a></p>
                </section>
            </footer>
        </>
    );
}

export default Footer;