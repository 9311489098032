import { Link } from 'react-router-dom';
import './style.css'
import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <section className='main'>
          <article className="main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>AUBIN RG</h1>
                <p className='main-title-desc'>director, producer, videographer</p>
              </div>
            </div>
          </article>

          <section className='main-cards'>
            <article className='main-card'>
            <div className='main-card-image1'></div>
              <h2 className='main-card-titre'>filming services</h2>
              <p className='main-card-desc'>Aubin is available as a video producer and as a Director with experience across multiple genres from interviews, to corporate films, commercials, and music videos.
              </p>
              <div className='main-card-explore'>
              <button className='main-card-button'><Link className='main-lien-button' to="/work">services</Link></button>
              </div>
            </article>
            <article className='main-card'>
              <div className='main-card-image2'></div>
              <h2 className='main-card-titre'>gear</h2>
              <p className='main-card-desc'>Sony A7s cameras, Tripods, Motorized slider, Stabilizer, HF lavalier microphones, HF handheld microphones, Drone, Green background, Lights
              </p>
              <div className='main-card-explore'>
              <button className='main-card-button'><Link className='main-lien-button' to="/gear">gear</Link></button>
              </div>
            </article>
          </section>
        </section>
      </main >
    </>
  );
}

export default Home;