import './style.css';
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  
  return (
    <>
      <main>
        <section className='main'>
          <article className="about-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>about</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
            {/* <div className='main-intro'>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis vel beatae commodi optio distinctio iure enim voluptatibus voluptate soluta id, praesentium minus ullam corporis dolores deserunt ipsam quibusdam.</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere quos natus id aliquid, ea aspernatur non neque libero doloremque! Vitae sint, suscipit sequi temporibus possimus cupiditate a in quibusdam ipsa.</p>
            </div> */}
          </article>

          <section className='about-main-cards'>
            {/* <h1 className='about-main-title'>clip "On The Beach"</h1> */}
            <article className='about-main-content'>
              {/* <div className='about-main-images'> */}
              {/* <div className='about-main-card-image2'></div> */}
              {/* </div> */}
              {/* <div className='about-main-card-text'> */}
              <div className='about-main-card'>
                <div className='about-main-card-image1'></div>
                <p className='about-main-card-desc'>Aubin RG is a nationally acclaimed filmmaker who, over his 16 year career, has specialized in creating high quality cinematic images, regardless of the camera. Some of his most popular works were created with the legendary Sony A7 cameras.</p>
                <p className='about-main-card-desc about-space-line'>Aubin has followed the advent of new tools in constant evolution. He currently owns Sony A7s2, Fx3, Fx6, Fs7, Canon EOS R5, Red Komodo 6k.</p>

                <p className='about-main-card-desc'>He has gained experience with almost every professional camera on the market.</p>
                <p className='about-main-card-desc'>He has worked with numerous media groups, such as Europe 1, Virgin Radio, as well as countless independent production companies.</p>
                <p className='about-main-card-desc'>Aubin divides his time between big budget projects and small independent projects.</p>
                <p className='about-main-card-desc'>For Aubin RG, independent projects are essential.</p>

                <div className='about-main-card-image2'></div>
                <p className='about-main-card-desc'>Its experience in the use of drones is constantly evolving and in line with the latest technologies on the market.</p>
                <p className='about-main-card-desc'>Aubin RG has used drones in several corporate and institutional video clip projects.</p>
                <p className='about-main-card-desc'>His creative abilities are limitless and he is always at the forefront of new technologies.</p>
                <p className='about-main-card-desc'>He frequently travels across France and Europe to collaborate and share his techniques with experienced and beginning filmmakers.</p>

                <div className='about-main-story-image'>
                  <div className='about-main-card-image3'></div>
                  <div className='about-main-card-image4'></div>
                </div>
                <div className='about-main-story-image'>
                  <div className='about-main-card-image5'></div>
                  <div className='about-main-card-image6'></div>
                </div>
                            <section className='about-main-button'>
              <div className='about-main-card-explore'>
              <button className='about-main-card-button'><Link className='main-lien-button' to="/work">services</Link></button>
              </div>
              <div className='about-main-card-explore'>
              <button className='about-main-card-button'><Link className='main-lien-button' to="/contact">hire aubin</Link></button>
              </div>
              <div className='about-main-card-explore'>
              <button className='about-main-card-button'><Link className='main-lien-button' to="/gear">gear</Link></button>
              </div>
              </section>
              </div>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}

export default About;
