import '../Work/style.css'
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';

function Realisation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <section className='main'>
          <article className="work-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='stock-main-title'>partenariat</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
          </article>

          <section className='work-main-cards'>
            <article className='work-main-content'>
              <div className='work-main-images'>
                <div className='work-main-card-image1'></div>
                <div className='work-main-card-image2'></div>
              </div>
              <article className='work-main-card'>
                <p className='work-main-card-desc'>
                Je travaille dans l’industrie de l’audiovisuel et du cinéma depuis 2006, en tant que réalisateur, cadreur, monteur vidéo, je fais également des effets spéciaux et du motion design.
                </p>
                <p className='work-main-card-desc'>
                À l’écoute et fort de mon expérience dans ce domaine depuis de nombreuses années, je propose à l’ensemble de mes clients, tout mon savoir-faire professionnel, créatif et technique pour tous leurs projets, mais aussi, un dévouement, une expertise et des conseils sans limites.
                </p>
                <p className='work-main-card-desc'>
                Je suis disponible comme réalisateur et vidéaste avec une expérience accrue pour vos différents projets allant de vidéos institutionnelles, l’interview face caméra simple, ou illustrée pour appuyer davantage vos propos, du streaming live pour vos séminaires, conférences, colloques, émissions tv, débats, concerts, ainsi que différents concepts d’événements, publicités et vidéoclips.
                </p>
                <p className='work-main-card-desc'>
                En plus de mon savoir-faire, je dispose d’une équipe technique professionnelle de cadreurs, monteurs vidéo, compositeurs, ingénieurs du son, tous vos projets sont réalisables dans les meilleures conditions professionnelles et techniques. N’hésitez pas à me contacter pour discuter de votre projet.J’ai à disposition une grande quantité d’équipements professionnels pour vos projets.
                </p>
                <p className='work-main-card-desc'>
                Avec des caméras, stabilisateurs, micros HF et des lumières pour tous vos budgets. Si vous souhaitez que votre production soit filmée et/ou montée en HD ; 4K ; 6k ; 8k je possède tous les outils qu’il vous faut.</p>
                {/* <p className='work-main-card-desc'>
                Avec des caméras, des lumières pour tous les budgets.
                </p>
                <p className='work-main-card-desc'>
                  Si vous souhaitez que votre production soit filmée en 360° ou montée en 4K ou 8K, nous avons les outils qu'il vous faut.
                </p> */}
              </article>
            </article>
            <h2 className='work-main-title'>ILS ME FONT CONFIANCES</h2>

            <div className='centered-window'>
              <div class="image-container">
                <img className='image-contener-size' src="/assets/img/Sneakers.png" alt="logo sneaker"></img>
                <img className='image-contener-size' src="/assets/img/virgin.png" alt="logo virgin"></img>
                <img className='image-contener-size' src="/assets/img/prosigma.jpeg" alt="logo prosigma"></img>
                <img className='image-contener-size' src="/assets/img/Bricodepot.png" alt="logo bircodepot"></img>
                <img className='image-contener-size' src="/assets/img/SAS.png" alt="logo sas"></img>
                <img className='image-contener-size' src="/assets/img/europe1.svg" alt="logo europe 1"></img>
                <img className='image-contener-size' src="/assets/img/newen.png" alt="logo newen"></img>
                <img className='image-contener-size' src="/assets/img/liik.jpeg" alt="logo liik"></img>
                <img className='image-contener-size' src="/assets/img/novocorp.jpeg" alt="logo novocorp"></img>
              </div>
            </div>
          <article className='work-main-card-btn'>
            <article className='work-main-button'>
              <div className='work-main-card-explore'>
              <button className='about-main-card-button'><Link className='main-lien-button' to="/stock-fr">réalisation</Link></button>
              </div>
              <div className='work-main-card-explore'>
                <button className='about-main-card-button'><Link className='main-lien-button' to="/contact-fr">travailler avec aubin</Link></button>
              </div>
              <div className='work-main-card-explore'>
                <button className='about-main-card-button'><Link className='main-lien-button' to="/gear-fr">équipement</Link></button>
              </div>
            </article>
          </article>
          </section>
        </section>
      </main >
    </>
  );
}

export default Realisation;