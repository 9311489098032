import './style.css';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../static/assets/img/logo/logo.png';

function Header() {
  const location = useLocation();
  const isFrUrl = location.pathname.endsWith('-fr');

  const linkText = {
    home: {
      '-fr': 'accueil',
      '': 'home',
    },
    about: {
      '-fr': 'à propos',
      '': 'about',
    },
    work: {
      '-fr': 'partenariat',
      '': 'work',
    },
    gear: {
      '-fr': 'équipement',
      '': 'gear',
    },
    stock: {
      '-fr': 'réalisation',
      '': 'stock',
    },
    contact: {
      '-fr': 'contact',
      '': 'contact',
    },
  };

  return (
    <header>
      <section className='header-contener'>
        <article className='header-box'>
          <Link to="/"><img className='header-logo' src={logo} alt="logo aubin" /></Link>
        </article>
        <nav className='header-nav'>
          <Link className='header-lien' to={isFrUrl ? '/home-fr' : '/home'}>{linkText.home[isFrUrl ? '-fr' : '']}</Link>
          <Link className='header-lien' to={isFrUrl ? '/about-fr' : '/about'}>{linkText.about[isFrUrl ? '-fr' : '']}</Link>
          <Link className='header-lien' to={isFrUrl ? '/work-fr' : '/work'}>{linkText.work[isFrUrl ? '-fr' : '']}</Link>
          <Link className='header-lien' to={isFrUrl ? '/gear-fr' : '/gear'}>{linkText.gear[isFrUrl ? '-fr' : '']}</Link>
          <Link className='header-lien' to={isFrUrl ? '/stock-fr' : '/stock'}>{linkText.stock[isFrUrl ? '-fr' : '']}</Link>
          <Link className='header-lien' to={isFrUrl ? '/contact-fr' : '/contact'}>{linkText.contact[isFrUrl ? '-fr' : '']}</Link>
        </nav>
      </section>
    </header>
  );
}

export default Header;
