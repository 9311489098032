import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import './style.css';

function Intro() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="intro-main">
      <article className="intro-main-image">
        <div className="intro-main-image-overlay">
          <div className="intro-overlay-text">
            <h1 className="intro-main-title">AUBIN RG</h1>
            <p className='intro-main-title-desc'>director, producer, filmmaker</p>
            <div className="language-selection">
              <Link
                className={`intro-main-lien`}
                to="/home-fr"
              >
                <div className="intro-main-icon">
                  <img src="/assets/img/icoFR.png" alt="francais" />
                  <p className="intro-main-icon-desc">bienvenue</p>
                </div>
              </Link>
              <Link
                className={`intro-main-lien`}
                to="/home"
              >
                <div className="intro-main-icon">
                  <img src="/assets/img/icoEN.png" alt="Anglais" />
                  <p className="intro-main-icon-desc">welcome</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </article>
    </main>
  );
}

export default Intro;
