import './style.css'
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';

function Work() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <section className='main'>
          <article className="work-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>work</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
          </article>

          <section className='work-main-cards'>
            <article className='work-main-content'>
              <div className='work-main-images'>
                <div className='work-main-card-image1'></div>
                <div className='work-main-card-image2'></div>
              </div>

              <article className='work-main-card'>
                <p className='work-main-card-desc'>
                  Since 2006, Aubin has worked in the audiovisual and cinema industry, he brings with him his creative experience, but also limitless passion and dedication.
                </p>
                <p className='work-main-card-desc'>
                  Aubin RG is available as a videographer and director with experience in several genres ranging from interviews, streaming, at events or for the web, to businesses, music videos, commercials, institutional videos, seminars, conferences.
                </p>
                <p className='work-main-card-desc'>
                  With Aubin and his professional team of videographer, camera operator, video editor, composer, sound engineers, all projects are achievable.
                </p>
                <p className='work-main-card-desc'>
                  Simply contact us to discuss your project.
                </p>
                <p className='work-main-card-desc'>
                  Aubin RG has a large quantity of equipment for your projects. With cameras, cameras, lights for all budgets.
                </p>
                <p className='work-main-card-desc'>
                  If you want your production to be filmed in 360° or edited in 4K or 8K, we have the tools you need.
                </p>
              </article>
            </article>

            <h2 className='work-main-title'>HERE ARE THE MEDIA GROUPS, COMPANIES AND CLIENTS I WORK WITH.</h2>

            <div className='centered-window'>
              <div class="image-container">
              <img className='image-contener-size' src="/assets/img/Sneakers.png" alt="logo sneaker"></img>
                <img className='image-contener-size' src="/assets/img/virgin.png" alt="logo virgin"></img>
                <img className='image-contener-size' src="/assets/img/prosigma.jpeg" alt="logo prosigma"></img>
                <img className='image-contener-size' src="/assets/img/Bricodepot.png" alt="logo bircodepot"></img>
                <img className='image-contener-size' src="/assets/img/SAS.png" alt="logo sas"></img>
                <img className='image-contener-size' src="/assets/img/europe1.svg" alt="logo europe 1"></img>
                <img className='image-contener-size' src="/assets/img/newen.png" alt="logo newen"></img>
                <img className='image-contener-size' src="/assets/img/liik.jpeg" alt="logo liik"></img>
                <img className='image-contener-size' src="/assets/img/novocorp.jpeg" alt="logo novocorp"></img>
              </div>
            </div>
          <article className='work-main-card-btn'>
            <article className='work-main-button'>
              <div className='work-main-card-explore'>
                <button className='about-main-card-button'><Link className='main-lien-button' to="/stock">stock</Link></button>
              </div>
              <div className='work-main-card-explore'>
                <button className='about-main-card-button'><Link className='main-lien-button' to="/contact">hire aubin</Link></button>
              </div>
              <div className='work-main-card-explore'>
                <button className='work-main-card-button'><Link className='main-lien-button' to="/gear">gear</Link></button>
              </div>
            </article>
          </article>
        </section>
          </section>

      </main >
    </>
  );
}

export default Work;