import './style.css';
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';

function Stock() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <section className='main'>
          <article className="stock-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>stock</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
          </article>

          <section className='stock-main-cards'>
            <article className='stock-main-content'>
              <div className='stock-main-card'>
                <p className='stock-main-card-desc'>Aubin RG offers you many clips that can also be licensed.</p>
                <p className='stock-main-card-desc'>Below are some examples of work available.</p>
                <div className='stock-main-story-video'>
                  <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/l_T7KXHYYpQ?si=sxay7L5GYpm07QVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/IGwWeEAc_zM?si=Tbjx_2doBMSD8z_i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='stock-main-story-video'>
                  <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/hIiff2j9L9E?si=0ETmYhUCt-AvgYMT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/XzIS1MjtGok?si=SySjnQ_o-F9a2gB6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/UBk0RzkHWXk?si=mtdX-yKyWzKsZ4Rt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/NVCeaHTjacY?si=EXz6zRissRTfmRSm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/AZK8HfaRr3M?si=Wczs9vdRiZLOVjo1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video'width="560" height="315" src="https://www.youtube.com/embed/A0TRNWohZm8?si=O4GSHw5Jnb8hLeSK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                {/* <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/AGy9wMYUasQ?si=L8yCRKAEH8uGyHRT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video'width="560" height="315" src="https://www.youtube.com/embed/iSU1xpBxK9U?si=KnfOd0Dejsc4-bfe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div> */}

                <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/ZXql6TysQqo?si=ILr-F8ZH_S2KXffM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/U0uhYSX-VzM?si=ANBr6HZ1p9dL-W1F" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/I3e0Aggt5jg?si=oBdJ1Qb3D2CJSMdZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/uERb-biR1Vk?si=89vPUiQBJtK9_Tn-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                {/* <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/m4SJg89hNDU?si=bUM4r0VrDDQcSUsJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/MZnHJ81TJ70?si=KUlvoJoP1hNLeveb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div> */}

                <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/thPER7qcHfU?si=5Y0_DVX0RUNzjsro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/JV6NZDzWJKo?si=Qz6T_HXfxJg5CCG-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='stock-main-story-video'>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/mT9--hTwwXY?si=pEzKVkGVZmk39bzN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className='stock-main-card-video' width="560" height="315" src="https://www.youtube.com/embed/HhvGEwtJgew?si=7qcMCLkG5E9ywJvY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

              <article className='stock-main-button'>
              <div className='stock-main-card-explore'>
                <button className='stock-main-card-button'><Link className='stock-lien-button' to="/work">services</Link></button>
              </div>
              <div className='stock-main-card-explore'>
                <button className='stock-main-card-button'><Link className='stock-lien-button' to="/contact">hire aubin</Link></button>
              </div>
              <div className='stock-main-card-explore'>
                <button className='stock-main-card-button'><Link className='stock-lien-button' to="/gear">gear</Link></button>
              </div>
            </article>
              </div>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}

export default Stock;
