import './style.css';
import { Outlet, Link } from "react-router-dom";
import { useEffect } from 'react';

  function Accueil() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main>
        <section className='main'>
          <article className="contact-main-image">
            <div className='main-image-overlay'>
              <div className="overlay-text">
                <h1 className='main-title'>contact</h1>
                <p className='main-title-desc'></p>
              </div>
            </div>
          </article>
          <article>
          </article>

          <section className='contact-main-cards'>
            <article className='contact-main-content'>
              <div className='contact-main-card'>
                <p className='contact-main-card-desc'>For all your questions about carrying out your project, please send me an email directly to the following address: 
                <a className='contact-main-mail' href="mailto:bookings@aubinrg.net"> bookings@aubinrg.net</a>
                </p>
                <p className='contact-main-card-desc'>AUBIN RG</p>
                <p className='contact-main-card-desc'>PARIS, FRANCE</p>
              </div>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}

export default Accueil;
